<template>
  <div>
    <div style="display: flex">
      <div style="min-width: 123px;height: 100%;padding-top: 6px;padding-left: 7px">
        <div class="role-name" style="color: #ff004d" @click="isShowAdd = true">＋ 添加角色</div>
        <div
            class="role-name"
            :class="{ active: item.id === selectedRoleId }"
            v-for="(item, index) in roleList"
            :key="index">
          <div @click="handleSelectRole(item.id, index)" style="flex: 1;padding-left: 7px">{{ item.name }}</div>

          <el-dropdown trigger="click" @command="handleCommand">
            <div style="transform: rotate(90deg);margin-right: 20px">
              <i class="el-icon-more el-icon--left el-icon--right"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{id: item.id, index, action: 'rename'}">重命名</el-dropdown-item>
              <el-dropdown-item :command="{id: item.id, index, action: 'delete'}">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div style="flex: 1;min-height: 750px;border-left: #f5f5f5 solid 1px">
        <div style="padding: 16px 40px;border-bottom: #f5f5f5 solid 1px">功能权限</div>
        <div style="padding-top: 36px" v-show="!!roleForm.id">
          <el-form ref="form" :model="form" label-width="80px">
            <el-checkbox-group v-model="roleForm.ids">
              <el-form-item :label="item.label" v-for="(item, index) in menu" :key="index" label-width="116px">
                <el-checkbox
                    :label="e.id"
                    v-for="(e, i) in item.children"
                    :key="i"
                    :disabled="isAdmin"
                    @click="selectedRoleId = e.id"
                    style="margin-left: 24px">{{ item.id === 1 && e.id === 8 ? '发行管理' : e.label }}</el-checkbox>
                <span v-if="item.id === 3" style="color: #c0c4cc;font-size: 12px;margin-left: 20px;">
                  此权限需联系管理员开通，如未开通此权限，勾选无效。
                </span>
              </el-form-item>
            </el-checkbox-group>
          </el-form>
          <div style="margin-left: 40px">
            <el-button class="but but-active" type="primary" @click="handleSubmit" :disabled="isAdmin">确 定</el-button>
            <el-button class="but" @click="roleForm.ids = []" :disabled="isAdmin">重 置</el-button>
          </div>
        </div>
        <div v-show="!roleForm.id" style="width: 100%;text-align: center;padding-top: 160px">
          <img src="../../assets/no-permission.png" alt="" style="width: 384px;height: 349px">
          <div style="color: #999999;font-size: 14px;text-align: center;margin-top: -60px">暂无权限角色</div>
        </div>
      </div>
    </div>
    <hi-custom-popup :show.sync="isShow" :title="'编辑角色'" but-show @confirm="handleRoleEdit" wide="500px">
      <el-form ref="form" :model="roleForm" style="margin-top: 30px;margin-bottom: 20px;">
        <el-input v-model.trim="roleForm.name" placeholder="请输入角色名">
          <span slot="prefix">角色</span>
        </el-input>
      </el-form>
    </hi-custom-popup>
    <hi-custom-popup :show.sync="isShowAdd" :title="'新增角色'" but-show @confirm="handleRoleAdd" wide="500px">
      <el-form ref="form" :model="form" style="margin-top: 30px;margin-bottom: 20px;">
        <el-input   v-model.trim="form.name" placeholder="请输入角色名">
          <div slot="prefix">角色</div>
        </el-input>
      </el-form>
    </hi-custom-popup>
  </div>
</template>

<script>
import request from "@/utils/request"
import hiCustomPopup from "@/components/HiCustomPopup"

const menu = [
  {
    id: 1,
    label: '作品管理',
    children: [
      {
        id: 6,
        label: '发布歌曲'
      },
      {
        id: 7,
        label: '出售词曲'
      },
      {
        id: 8,
        label: '歌曲管理'
      },
      {
        id: 9,
        label: '词曲管理'
      }
    ]
  },
  {
    id: 2,
    label: '数据中心',
    children: [
      {
        id: 10,
        label: '收益数据'
      },
      {
        id: 11,
        label: '作品表现'
      }
    ]
  },
  {
    id: 3,
    label: '词曲选购',
    children: [
      {
        id: 12,
        label: '词曲选购'
      }
    ]
  },
  {
    id: 4,
    label: '机构管理',
    children: [
      {
        id: 17,
        label: '机构设置'
      },
      {
        id: 14,
        label: '成员管理'
      },
      {
        id: 13,
        label: '成员动态'
      },
      {
        id: 16,
        label: '艺人管理'
      }
    ]
  },
  // {
  //   id: 5,
  //   label: '系统管理',
  //   children: [
  //     {
  //       id: 17,
  //       label: '账户与安全'
  //     }
  //   ]
  // },
]

export default {
  name: "Permission",
  components: {hiCustomPopup},
  mounted() {
    this.getRoles()

  },
  data() {
    return {
      form: {
        name: ''
      },
      menu,
      isShow: false,
      isShowAdd: false,
      roleForm: {
        id: undefined,
        name: '',
        ids: []
      },
      selectedRoleId: undefined,
      // selectedRoleIndex: undefined,
      roleList: []
    }
  },
  methods: {
    handleCommand(c){
      if (c.action === 'rename'){
        this.handleSelectRole(c.id, c.index)
        this.isShow = true
      } else if(c.action === 'delete'){
        request.post('/api/company/role/add', {
          id: c.id,
          status: -1
        }).then(r => {
          if (r.code === 0) {
            this.roleForm.name = ''
            this.roleForm.id = undefined
            this.getRoles()
          }else {
            this.$toast(r.msg)
          }
        })
      }
    },
    getRoles(){
      request.post('/api/company/role/list', {}).then(r => {
        if(r.code === 0 && !!this.roleList) {
          this.roleList = r.data.filter(e => e.type !== 0)
          if (!!this.roleList && this.roleList.length > 0 && !this.selectedRoleId) {
            this.handleSelectRole(this.roleList[0].id, 0)
          }
        }
      })
    },
    handleRoleAdd() {
      if (!!this.form.name && this.form.name.length > 0)
        request.post('/api/company/role/add', this.form).then(r => {
          if (r.code === 0) {
            this.form.name = ''
            this.isShowAdd = false
            this.getRoles()
          }
        })
    },
    handleRoleEdit() {
      if (!!this.roleForm.name && this.roleForm.name.length > 0)
        request.post('/api/company/role/add', this.roleForm).then(r => {
          if (r.code === 0) {
            this.isShow = false
            this.getRoles()
            // this.$message.success('操作成功')
            this.$popup({msg:'权限设置修改成功',title:'提示',type:'success'})
          } else {
            // this.$message.error()
            this.$popup(  {msg:r.msg || '操作失败',title:'提示',type:'error'})
          }
        })
    },
    handleSelectRole(id, index) {
      this.selectedRoleId = id
      this.roleForm.id = id
      this.roleForm.name = this.roleList[index].name
      this.roleForm.ids = []

      const subIds = this.roleList[index].subIds
      if (subIds) {
        this.roleForm.ids = subIds
      }
    },
    handleSubmit(){
      this.handleRoleEdit()
    }
  },
  computed: {
    isAdmin(){
      const roleInfo = this.roleList.find(e => e.id === this.roleForm.id)
      return roleInfo ? roleInfo.type === 0 : false
    }
  }
}
</script>

<style scoped lang="scss">
.el-divider::v-deep{
  margin-bottom: 0;
}
.role-name{
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  color: #AAAAAA;
  display: flex;
  font-size: 14px;
}
.role-name.active{
  color: #000000;
}
.but{
  border-radius: 3px;
  width: 188px;
  height: 42px;
  background: #f7f7f7;
  border: none;
  margin-right: 20px;
}
.but-active{
  background-color: #FF004D;
}

::v-deep .el-form-item__label{
  color: #999999;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
}
::v-deep .el-checkbox-group{
  .el-checkbox{
     .el-checkbox__label{
       font-size: 14px;
       font-family: PingFang SC, PingFang SC-Regular;
       font-weight: 400;
       text-align: LEFT;
       color: #666666;
     }
  }
}
::v-deep .el-form{
  .el-input {
    .el-input__inner{
      border: none;
      border-bottom: 1px solid #eeeeee;
      text-align: right;
    }
    .el-input__prefix{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
    }
  }
}

</style>
